import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { Apollo, gql } from 'apollo-angular';
import { Observable, map } from 'rxjs';
import {
  DataMappingInput,
  DataMappingResponse,
  IAddOrUpdateDataMappingResponse,
} from '../../../models/subTenant.interface';
import {
  GetAllSubTenantOriginatorNameQuery,
  GetSubTenantLegalNameQuery,
} from './subtenant-graphql.types';

@Injectable({
  providedIn: 'root',
})
export class SubtenantGraphqlApisService {
  private GET_DATA_MAPPING_BY_SUBTENANT = gql`
    query GetSubTenantDataMapping($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        dataMappings {
          subTenantId
          mappings {
            fieldName
            enumValues {
              enumValue
              aliases
            }
          }
        }
      }
    }
  `;

  private GET_DATA_MAPPING_PRODUCT_NAMES_BY_SUBTENANT = gql`
    query getSubTenant($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        products {
          productName
        }
      }
    }
  `;

  private ADD_OR_UPDATE_DATA_MAPPINGS = gql`
    mutation DataMappingsUpsert($dataMappingInput: DataMappingInput!) {
      dataMappingsUpsert(dataMappingInput: $dataMappingInput) {
        subTenantId
        mappings {
          fieldName
          enumValues {
            aliases
            enumValue
          }
        }
      }
    }
  `;

  private GET_ALL_SUB_TENANTS_SUMMARY = gql`
    query subTenantSummary($count: Int, $tenantId: String) {
      subTenants(count: $count, tenantId: $tenantId) {
        subTenantId
        originatorName
      }
    }
  `;

  private GET_SUB_TENANT_LEGAL_NAME = gql`
    query GetSubtenantLegalName($subTenantId: ID!) {
      subTenant(subTenantId: $subTenantId) {
        legalName
      }
    }
  `;

  getDataMappingsBySubTenant(
    subTenantId: string
  ): Observable<DataMappingResponse> {
    return this.apollo
      .query<DataMappingResponse>({
        query: this.GET_DATA_MAPPING_BY_SUBTENANT,
        variables: {
          subTenantId: subTenantId,
        },
      })
      .pipe(map(({ data }) => data));
  }

  getDataMappingsProductNamesBySubTenant(subTenantId: string) {
    return this.apollo
      .query({
        query: this.GET_DATA_MAPPING_PRODUCT_NAMES_BY_SUBTENANT,
        variables: {
          subTenantId: subTenantId,
        },
      })
      .pipe(map(({ data }) => data));
  }

  addOrUpdateDataMappings(
    dataMappingInput: DataMappingInput
  ): Observable<IAddOrUpdateDataMappingResponse> {
    return this.apollo
      .mutate<IAddOrUpdateDataMappingResponse>({
        mutation: this.ADD_OR_UPDATE_DATA_MAPPINGS,
        variables: { dataMappingInput: dataMappingInput },
      })
      .pipe(map((res) => res!.data!));
  }

  getAllSubTenantsSummaryWithTenantId(count: number, tenantId: string): any {
    return this.apollo.query({
      query: this.GET_ALL_SUB_TENANTS_SUMMARY,
      variables: {
        count: count,
        tenantId: tenantId,
      },
    });
  }

  getAllSubTenantOriginatorName(): Observable<
    ApolloQueryResult<GetAllSubTenantOriginatorNameQuery>
  > {
    return this.apollo.query<GetAllSubTenantOriginatorNameQuery>({
      query: this.GET_ALL_SUB_TENANTS_SUMMARY,
    });
  }

  getSubTenantLegalName(
    id: string
  ): Observable<ApolloQueryResult<GetSubTenantLegalNameQuery>> {
    return this.apollo.query<GetSubTenantLegalNameQuery>({
      query: this.GET_SUB_TENANT_LEGAL_NAME,
      variables: {
        subTenantId: id,
      },
    });
  }

  constructor(private apollo: Apollo) {}
}
