import { Injectable } from '@angular/core';
import { Apollo, gql } from 'apollo-angular';
import { Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import {
  GetTenantNameAndConfigurationsQuery,
  GetTenantTemplatesByIdQuery,
} from './tenant-graphql.types';

export const GET_TENANT_TEMPLATES_BY_ID = gql`
  query GetTenantById {
    getTenantById {
      tenantId
      templates {
        documentId
        documentType
        documentName
        documentURL
        fileContent
        createdOn
        size
      }
      defaultTemplate
    }
  }
`;

export const GET_TENANT_NAME_AND_CONFIGURATIONS = gql`
  query GetTenantNameByTenantId($tenantId: String!) {
    getTenantByTenantId(tenantId: $tenantId) {
      name
      configurations {
        intelligence
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TenantGraphqlApisService {
  constructor(private apollo: Apollo) {}

  getTenantTemplatesById(): Observable<
    ApolloQueryResult<GetTenantTemplatesByIdQuery>
  > {
    return this.apollo.query<GetTenantTemplatesByIdQuery>({
      query: GET_TENANT_TEMPLATES_BY_ID,
    });
  }

  getTenantNameAndConfigurations(
    id: string
  ): Observable<ApolloQueryResult<GetTenantNameAndConfigurationsQuery>> {
    return this.apollo.query<GetTenantNameAndConfigurationsQuery>({
      query: GET_TENANT_NAME_AND_CONFIGURATIONS,
      variables: {
        tenantId: id,
      },
    });
  }
}
