<header>
  <ng-container *ngIf="auth.isAuthenticated$ | async">
    <div class="flex flex-wrap align-items-center justify-content-between">
      <div class="flex flex-wrap align-items-center justify-content-start">
        <div
          class="flex flex-wrap justify-content-start align-items-center mr-4"
        >
          <h1
            class="flex-order-1"
            [class.ml-1]="!isLargeScreen"
            (click)="turnOffMobileNav()"
          >
            <a
              class="flex flex-wrap align-items-center no-underline"
              routerLink="/secure/dashboard/landing"
              >Agility</a
            >
          </h1>
          <button
            *ngIf="!isLargeScreen"
            id="hamBurgerMenuIcon"
            (keydown.esc)="onEscKeyDown()"
            (click)="toggleMobileNav()"
            pButton
            pRipple
            type="button"
            [icon]="isMobileNavTurnedOn ? 'pi pi-times' : 'pi pi-bars'"
            class="flex-order-0 my-2"
            aria-label="'header.nav.ariaLabels.menuIcon' | translate"
            aria-describedby="mobileAppNav"
          ></button>
        </div>
        <app-navigation *ngIf="isLargeScreen"></app-navigation>
        <app-mobile-navigation
          *ngIf="!isLargeScreen"
          [show-mobile-nav]="isMobileNavTurnedOn"
        ></app-mobile-navigation>
      </div>
      <div class="flex flex-wrap">
        <nav>
          <ul class="nav justify-content-center">
            <li *ngIf="userType === tenantUser && isIntelligenceEnabled">
              <button
                id="powerBiButton"
                pButton
                pRipple
                type="button"
                icon="pi"
                class="p-button-rounded p-button-help my-2"
                aria-label="'header.nav.ariaLabels.powerBi' | translate"
                [routerLink]="['/secure/dashboard/powerbi']"
                (mouseover)="powerBiButtonMouseHover()"
                (mouseleave)="powerBiButtonMouseLeave()"
                [pTooltip]="
                  'header.nav.tooltips.agilityIntelligence' | translate
                "
                tooltipPosition="bottom"
              >
                <img
                  alt="power-bi icon"
                  class="custom-power-bi-icon"
                  [src]="
                    hover || powerBiIconClicked ? powerbi_light : powerbi_dark
                  "
                />
              </button>
            </li>
            <li>
              <button
                (click)="search.toggle($event)"
                pButton
                pRipple
                type="button"
                icon="pi pi-search"
                class="p-button-rounded p-button-help ml-2 my-2"
                aria-label="'header.nav.ariaLabels.searchIcon' | translate"
                [pTooltip]="'header.nav.tooltips.loanSearch' | translate"
                tooltipPosition="bottom"
              ></button>
            </li>
            <!-- <li (click)="turnOffMobileNav()">
              <button
                pButton
                pRipple
                type="button"
                icon="pi pi-bell"
                class="p-button-rounded p-button-help ml-2 my-2"
                aria-label="'header.nav.ariaLabels.notificationsIcon' | translate"
              ></button>
            </li> -->
            <li>
              <button
                (click)="menu.toggle($event)"
                pButton
                pRipple
                type="button"
                icon="pi pi-user"
                class="p-button-rounded p-button-help ml-2 my-2"
                aria-label="'header.nav.ariaLabels.profileIcon' | translate"
                [pTooltip]="'header.nav.tooltips.userProfile' | translate"
                tooltipPosition="bottom"
              ></button>
            </li>
            <p-menu
              #menu
              class="user-menu"
              [model]="items"
              [popup]="true"
              appendTo="body"
            ></p-menu>
            <p-overlayPanel #search appendTo="body">
              <ng-template pTemplate="content">
                <app-global-loan-search
                  (closeOverlayPanel)="closeOverlayPanel($event, search)"
                  [recentlyViewedLoanSize]="recentlyViewedLoanSize"
                >
                </app-global-loan-search>
              </ng-template>
            </p-overlayPanel>
          </ul>
        </nav>
      </div>
    </div>
  </ng-container>
  <ng-container *ngIf="(auth.isAuthenticated$ | async) === false">
    <div class="flex flex-wrap align-items-center justify-content-between">
      <div class="flex flex-wrap justify-content-start align-items-center">
        <h1
          class="flex-order-1"
          [class.ml-1]="!isLargeScreen"
          (click)="turnOffMobileNav()"
        >
          <a
            class="flex flex-wrap align-items-center no-underline"
            routerLink="/public"
            >{{ agilityAppTitle }}</a
          >
        </h1>
      </div>
    </div>
  </ng-container>
</header>
