import { Injectable } from '@angular/core';
import { Apollo, gql, MutationResult } from 'apollo-angular';
import { map, Observable } from 'rxjs';
import { ApolloQueryResult } from '@apollo/client';
import {
  GetTenantNameAndConfigurationsQuery,
  GetTenantSelfCustodianByTenantIdQuery,
  GetTenantTemplatesByIdQuery,
} from './tenant-graphql.types';
import {
  GetTenantDataMappingResponse,
  TenantDataMapping,
  TenantDataMappingsUpsertResponse,
} from 'src/app/shared/models/tenant.interface';
import { Mapping } from 'src/app/shared/models/subTenant.interface';

export const GET_TENANT_TEMPLATES_BY_ID = gql`
  query GetTenantTemplates {
    getTenantById {
      tenantId
      templates {
        documentId
        documentType
        documentName
        documentURL
        fileContent
        createdOn
        size
      }
      defaultTemplate
    }
  }
`;

export const GET_TENANT_SELF_CUSTODIAN_BY_ID = gql`
  query GetTenantSelfCustodianById($tenantId: String!) {
    getTenantByTenantId(tenantId: $tenantId) {
      custodianNames
      selfCustodian
    }
  }
`;

export const GET_TENANT_NAME_AND_CONFIGURATIONS = gql`
  query GetTenantNameByTenantId($tenantId: String!) {
    getTenantByTenantId(tenantId: $tenantId) {
      name
      configurations {
        intelligence
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class TenantGraphqlApisService {
  private ADD_OR_UPDATE_DATA_MAPPINGS = gql`
    mutation TenantDataMappingsUpsert($input: [TenantDataMappingInput]) {
      tenantDataMappingsUpsert(input: $input) {
        fieldName
        enumValues {
          aliases
          enumValue
        }
      }
    }
  `;

  private GET_DATA_MAPPING_BY_TENANT = gql`
    query GetTenantDataMappingsByTenantId {
      getTenantById {
        dataMappings {
          fieldName
          enumValues {
            enumValue
            aliases
          }
        }
      }
    }
  `;

  constructor(private apollo: Apollo) {}

  addOrUpdateDataMappings(
    input: Mapping[]
  ): Observable<MutationResult<TenantDataMappingsUpsertResponse>> {
    return this.apollo.mutate<TenantDataMappingsUpsertResponse>({
      mutation: this.ADD_OR_UPDATE_DATA_MAPPINGS,
      variables: { input },
    });
  }

  getDataMappingsByTenant(): Observable<
    ApolloQueryResult<GetTenantDataMappingResponse>
  > {
    return this.apollo.query<GetTenantDataMappingResponse>({
      query: this.GET_DATA_MAPPING_BY_TENANT,
    });
  }

  getTenantTemplatesById(): Observable<
    ApolloQueryResult<GetTenantTemplatesByIdQuery>
  > {
    return this.apollo.query<GetTenantTemplatesByIdQuery>({
      query: GET_TENANT_TEMPLATES_BY_ID,
    });
  }

  getTenantSelfCustodianByTenantId(
    tenantId: string
  ): Observable<ApolloQueryResult<GetTenantSelfCustodianByTenantIdQuery>> {
    return this.apollo.query<GetTenantSelfCustodianByTenantIdQuery>({
      query: GET_TENANT_SELF_CUSTODIAN_BY_ID,
      variables: {
        tenantId: tenantId,
      },
    });
  }

  getTenantNameAndConfigurations(
    id: string
  ): Observable<ApolloQueryResult<GetTenantNameAndConfigurationsQuery>> {
    return this.apollo.query<GetTenantNameAndConfigurationsQuery>({
      query: GET_TENANT_NAME_AND_CONFIGURATIONS,
      variables: {
        tenantId: id,
      },
    });
  }
}
