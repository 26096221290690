import { Injectable } from '@angular/core';
import { FormArray, FormBuilder } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoanConditionsService {
  private _conditions$: BehaviorSubject<FormArray> =
    new BehaviorSubject<FormArray>(this.fb.array([]));
  private _activeAccordianTabName$: BehaviorSubject<string> =
    new BehaviorSubject<string>('');
  constructor(private fb: FormBuilder) {}

  getConditions(): BehaviorSubject<FormArray> {
    return this._conditions$;
  }
  setConditions(conditions: FormArray) {
    this._conditions$.next(conditions);
  }

  getActiveConditionsCount() {
    let count = 0;
    this.getConditions()
      .getValue()
      .controls.forEach((condition) => {
        if (condition.get('isActive')?.value) {
          count++;
        }
      });
    return count;
  }

  getClearedConditionsCount() {
    let count = 0;
    this.getConditions()
      .getValue()
      .controls.forEach((condition) => {
        if (!condition.get('isActive')?.value) {
          count++;
        }
      });
    return count;
  }

  getActiveHardConditionsCount() {
    let count = 0;
    count = this.getConditions()
      .getValue()
      .controls.filter(
        (condition: any) =>
          condition.get('isActive')?.value &&
          !condition.get('softCondition')?.value
      ).length;
    return count;
  }

  getActiveSoftConditionsCount() {
    let count = 0;
    count = this.getConditions()
      .getValue()
      .controls.filter(
        (condition: any) =>
          condition.get('isActive')?.value &&
          condition.get('softCondition')?.value
      ).length;
    return count;
  }

  getActiveAccordianTabName(): BehaviorSubject<string> {
    return this._activeAccordianTabName$;
  }
  setActiveAccordianTabName(tabIndex: string) {
    this._activeAccordianTabName$.next(tabIndex);
  }
}
