import { AGILITY_APP_TITLE } from '../../../secure/funding/constants/agility-title.const';
import { ApolloQueryResult } from '@apollo/client/core';
import { AuthService } from '@auth0/auth0-angular';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';
import { DOCUMENT } from '@angular/common';
import { GetSubTenantLegalNameQuery } from '../../services/graphql-apis/subTenant/subtenant-graphql.types';
import { GetTenantNameAndConfigurationsQuery } from '../../services/graphql-apis/tenant/tenant-graphql.types';
import { LocalStorageService } from '../../services/local-storage/local-storage.service';
import { MenuItem } from 'primeng/api/menuitem';
import { Observable, of } from 'rxjs';
import { Router } from '@angular/router';
import { SubtenantGraphqlApisService } from '../../services/graphql-apis/subTenant/subtenant-graphql-apis.service';
import { switchMap } from 'rxjs/operators';
import { TenantGraphqlApisService } from '../../services/graphql-apis/tenant/tenant-graphql-apis.service';
import { TranslateService } from '@ngx-translate/core';
import { UserService } from '../../services/user.service';
import { UserType } from '../../models/user-enumeration';

type HeaderUserInfoIntersectionType = GetSubTenantLegalNameQuery &
  GetTenantNameAndConfigurationsQuery;

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit {
  hover = false;
  powerBiIconClicked = false;
  userType!: string;
  powerbi_light = '../../../../assets/images/powerbi-light.svg';
  powerbi_dark = '../../../../assets/images/powerbi-dark.svg';
  isMobileNavTurnedOn = false;
  isLargeScreen = false;
  private _getScreenWidth = 0;
  items: MenuItem[] = [];
  userName?: string;
  name!: string | undefined;
  recentlyViewedLoanSize: number = 4;
  tenantUser: string = 'TENANT';
  powerbiButton: string = 'powerBiButton';
  agilityAppTitle = AGILITY_APP_TITLE;
  isIntelligenceEnabled = false;

  constructor(
    public translate: TranslateService,
    public auth: AuthService,
    public router: Router,
    public localStorage: LocalStorageService,
    private userService: UserService,
    private subtenantGraphqlApisService: SubtenantGraphqlApisService,
    private tenantGraphqlApisService: TenantGraphqlApisService,
    @Inject(DOCUMENT) private doc: Document
  ) {
    this.userType = this.userService.userType;
  }

  ngOnInit(): void {
    this.userName = this.localStorage.getLocalStorageItem(
      CustomEventTokens.AGILITY_USER
    )?.claims?.fullName;

    if (!this.userService.isSuperTenant) {
      of(this.userService.userType)
        .pipe(
          switchMap(
            (
              userType
            ): Observable<
              ApolloQueryResult<HeaderUserInfoIntersectionType>
            > => {
              return userType === UserType.SUB_TENANT
                ? (this.subtenantGraphqlApisService.getSubTenantLegalName(
                    this.userService.subTenantId
                  ) as Observable<
                    ApolloQueryResult<HeaderUserInfoIntersectionType>
                  >)
                : (this.tenantGraphqlApisService.getTenantNameAndConfigurations(
                    this.userService.tenantId
                  ) as Observable<
                    ApolloQueryResult<HeaderUserInfoIntersectionType>
                  >);
            }
          )
        )
        .subscribe(({ data: { subTenant, getTenantByTenantId } }) => {
          if (subTenant || getTenantByTenantId) {
            this.name =
              subTenant?.legalName ||
              getTenantByTenantId?.name ||
              this.userName;
            this.isIntelligenceEnabled =
              getTenantByTenantId?.configurations?.intelligence || false;
            this.getHeaderList();
          }
        });
    } else {
      this.getHeaderList();
    }

    this.setScreenWidth(window.innerWidth);
    this.isLargeScreen = this.isScreenSizeLarge();
  }

  getHeaderList() {
    this.items = [
      {
        label: `Hello, ${this.userName}`,
        title: this.userName,
        styleClass: 'user-name',
        disabled: true,
      },
      {
        label: this.name,
        title: this.name,
        styleClass: 'profile-tenant-name',
        disabled: true,
      },
      {
        label: 'View Profile',
        command: () => {
          this.router.navigate(['/secure/profile/view-profile']);
        },
      },
      {
        label: 'Sign Out',
        command: () => {
          this.logOut();
        },
      },
    ];
  }

  onEscKeyDown(): void {
    this.turnOffMobileNav();
  }

  toggleMobileNav(): void {
    this.isMobileNavTurnedOn = !this.isMobileNavTurnedOn;
  }

  turnOffMobileNav(): void {
    this.isMobileNavTurnedOn = false;
  }

  @HostListener('window:resize', ['$event'])
  onWindowResize(): void {
    this.setScreenWidth(window.innerWidth);
    this.isLargeScreen = this.isScreenSizeLarge();
    if (this.isLargeScreen) {
      this.isMobileNavTurnedOn = false;
    }
  }

  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    this.powerBiIconClicked =
      (event.target as HTMLElement).id === this.powerbiButton;
  }

  isScreenSizeLarge(): boolean {
    if (this.getScreenWidth() >= 1144) {
      return true;
    } else {
      return false;
    }
  }

  getScreenWidth(): number {
    return this._getScreenWidth;
  }

  setScreenWidth(width: number): void {
    this._getScreenWidth = width;
  }

  closeOverlayPanel($event: any, op: any) {
    op.toggle($event);
  }

  logOut(): void {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }

  powerBiButtonMouseHover() {
    this.hover = true;
  }

  powerBiButtonMouseLeave() {
    this.hover = false;
  }
}
