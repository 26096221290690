import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class ProgressSpinnerService {
  constructor() {}

  private progressSpinnerStateSource = new Subject<boolean>();
  public progressSpinnerState$: Observable<boolean> =
    this.progressSpinnerStateSource.asObservable();

  setProgressSpinnerState(value: boolean) {
    this.progressSpinnerStateSource.next(value);
  }
}
