import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client';
import { UUID } from 'angular2-uuid';
import { Observable, delay, of } from 'rxjs';
import { Mutation } from 'src/app/graphql/generated';
import { environment } from 'src/environments/environment';
import { LinkToLoanInput } from '../../models/LinkToLoanInput.interface';
import { AddDocument } from '../../models/add-document.interface';
import { AddLoansToWire } from '../../models/add-loans-to-wire.interface';
import { ClosingAgentInformation } from '../../models/closing-agent-information.Interface';
import { ImbWireInput } from '../../models/imb-wire-input.interface';
import { LinkWire } from '../../models/link-wire.interface';
import { SettlementWire } from '../../models/settlement-wire.interface';
import { SubTenant } from '../../models/subTenant.interface';
import { Tenant } from '../../models/tenant.interface';
import { UpdateImbWireInput } from '../../models/update-imb-wire-input.interface';
import { UpdateLoanCondition } from '../../models/update-loan-condition.interface';
import { UtilsService } from '../utils/utils.service';
import {
  SETTELEMENT_DOCUMENT_TYPES,
  SETTLEMENT_REFERENCE_TYPES,
} from '../../components/constant/settlement.constants';

const uri = environment.gqlUri;
const productMutation = `products { productId productName productType mraDescription subLimitAccount{limit} subLimit subLimitBasis limit advanceBasis indexBasis indexName subBasis eNote autoFund monthlyInterestCollection status productFloorRate indexUpdateFrequency indexUpdateFreqValue adjuster interestAccrualMethod requiredDocs { file } additionalDraws { file } additionalDrawsFlag productFees { dailyWetFee wireFee fileFee eNoteFee shippingFee drawFee } investorsList dwellPeriodsList { minDwellPeriod maxDwellPeriod marginInc agingFee advanceRate commentReq } rules { maxLoanAmount minFrontEndDti loanTermLimit maxLtv minCreditScore minLoanAmount maxCLTV noteDate ssnRequired occupancy states docTypes amortization maxLoanAmountActive minFrontEndDtiActive loanTermLimitActive maxLtvActive minCreditScoreActive minLoanAmountActive maxCLTVActive noteDateActive ssnRequiredActive occupancyActive statesActive docTypesActive amortizationActive maxFrontEndDti maxBackEndDti minimumNoteAmount maximumNoteAmount lienPosition propertyType maxCreditScore maxFrontEndDtiActive maxBackEndDtiActive minimumNoteAmountActive maximumNoteAmountActive lienPositionActive propertyTypeActive maxCreditScoreActive } }`;

@Injectable({
  providedIn: 'root',
})
export class ApiCallsService {
  constructor(private http: HttpClient, private utilsService: UtilsService) {}

  createImbProfile(email: string): Observable<any> {
    return of(email).pipe(delay(500));
  }

  saveTheme(theme: string): Observable<any> {
    /* 
      Need to un-comment the below return statement once the actual service to save the theme selection is available.
      Need to remove the active return statement as well.
    */
    //return this.http.post<any>(`${environment.apiUrl}/api/v1/users/save-theme`, { theme });
    return of(theme).pipe(delay(500));
  }

  saveLanguage(language: string): Observable<any> {
    /* 
      Need to un-comment the below return statement once the actual service to save the preferred language is available.
      Need to remove the active return statement as well.
    */
    //return this.http.post<any>(`${environment.apiUrl}/api/v1/users/save-language`, { language });
    return of(language).pipe(delay(500));
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  downloadDocument(downloadURL: string): Observable<Blob> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const path = environment.apiUrl + '/download?url=' + downloadURL;
    return this.http.get(path, { responseType: 'blob', headers: headers });
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addTenant(tenantObj: Tenant, tenantLogo: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($tenantId: String, $name : String, $email : String, $loginUserEmail : String, $url : String, $attn: String, $bankName: String, $abaNumber: String,$accountNumber : String, $accountName : String, $lenderAddressStreet: String, $lenderAddressCity: String, $lenderAddressState: String, $lenderAddressZip: String, $selfCustodian: Boolean, $company:CompanyInput, $custodianNames: [String], $isMessageActive:Boolean ,$message:String, $document:DocumentInput){ addTenant(tenant:{ tenantId: $tenantId, name :$name, url : $url,loginUserEmail : $loginUserEmail, email : $email, attn : $attn, bankName : $bankName, abaNumber : $abaNumber, accountNumber : $accountNumber, accountName : $accountName, lenderAddressStreet: $lenderAddressStreet, lenderAddressCity: $lenderAddressCity, lenderAddressState: $lenderAddressState, lenderAddressZip: $lenderAddressZip, selfCustodian : $selfCustodian, company : $company, custodianNames: $custodianNames,  isMessageActive:$isMessageActive ,message:$message,document: $document}){ tenantId active name url document{documentURL documentId}}}',
      variables: JSON.parse(JSON.stringify(tenantObj), this.omitTypename),
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (tenantLogo && tenantLogo instanceof File) {
      formData.append('logo_tenantLogo', tenantLogo, tenantLogo.name);
    }
    return this.http.post<any>(
      `${environment.apiUrl}/graphql`,
      formData,
      requestOptions
    );
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addSubTenant(subTenant: SubTenant, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($subTenantId: ID, $originatorName: String!,$legalName: String, $dba:String, $legalType: String, $approvalStatus: String, $comment: String, $accManager: String, $agreementDate:String, $primaryContact: PrimaryContactInput, $mailingAddress: MailingAddressInput, $accountSetups:[AccountSetupInput], $lineDetails: LineDetailsInput, $products: [ProductInput],$subTenantFees: SubTenantFeesInput, $eOInsurance: String!, $insurance: String!, $aggregateLimit: String!, $occurrenceLimit: String!, $insuranceExp: String!, $bondLimit: String!, $loanIdPrefix: String!) { subTenantUpsert(subTenant: {subTenantId: $subTenantId, originatorName: $originatorName, legalName: $legalName, dba: $dba, legalType:$legalType, approvalStatus: $approvalStatus, comment: $comment, accManager: $accManager, agreementDate: $agreementDate, primaryContact: $primaryContact, mailingAddress: $mailingAddress, accountSetups:$accountSetups, lineDetails: $lineDetails, products: $products,subTenantFees:$subTenantFees , eOInsurance:$eOInsurance, insurance:$insurance, aggregateLimit:$aggregateLimit, occurrenceLimit:$occurrenceLimit, insuranceExp:$insuranceExp, bondLimit:$bondLimit, loanIdPrefix:$loanIdPrefix }) { subTenantId originatorName legalName dba legalType approvalStatus comment accManager agreementDate primaryContact { contactName title email fax } mailingAddress { address1 address2 city state zip } accountSetups { accountId accountNumber accountType bankBalance aba bankAccountType bankName minResBalance accountName originatorAccount {balance}}' +
        productMutation +
        'lineDetails { committedAmt uncommittedAmt commitmentAmount tempCommitmentAmount amendmentDate commitmentApprove wetLimit tempWetLimit wetApprove wetDwellDays eNoteDwellDays qcFileReview autoFundAddWires indexUpdateFrequency indexUpdateFreqValue indexAdjuster wetCurtailment startDate endDate firstDaysOfMon lastDaysOfMon contractEffectiveDate contractExpirationDate createdBy createdDate updatedBy updatedDate document {createdBy createdOn documentId documentName documentType documentURL updatedBy updatedOn size } } tenantId } }',
      variables: subTenant,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append('lineDetails_contractDocumentUpload', file, file.name);
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  updateSubTenant(
    subTenantId: string,
    subTenant: SubTenant,
    file: File
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($subTenantId: ID,$originatorName: String!,$legalName: String, $dba:String, $legalType: String, $approvalStatus: String, $comment: String, $accManager: String, $agreementDate:String, $primaryContact: PrimaryContactInput, $mailingAddress: MailingAddressInput, $accountSetups:[AccountSetupInput], $lineDetails: LineDetailsInput, $products: [ProductInput],$subTenantFees: SubTenantFeesInput, $eOInsurance: String!, $insurance: String!, $aggregateLimit: String!, $occurrenceLimit: String!, $insuranceExp: String!, $bondLimit: String!, $loanIdPrefix: String!) { subTenantUpsert(subTenant: { subTenantId: $subTenantId, originatorName: $originatorName, legalName: $legalName, dba: $dba, legalType:$legalType, approvalStatus: $approvalStatus, comment: $comment, accManager: $accManager, agreementDate: $agreementDate, primaryContact: $primaryContact, mailingAddress: $mailingAddress,accountSetups:$accountSetups, lineDetails: $lineDetails, products: $products,subTenantFees:$subTenantFees , eOInsurance:$eOInsurance, insurance:$insurance, aggregateLimit:$aggregateLimit, occurrenceLimit:$occurrenceLimit, insuranceExp:$insuranceExp, bondLimit:$bondLimit, loanIdPrefix: $loanIdPrefix }) { subTenantId originatorName legalName dba legalType approvalStatus comment accManager agreementDate primaryContact { contactName title email fax } mailingAddress { address1 address2 city state zip } accountSetups { accountId accountNumber accountType bankBalance aba bankAccountType bankName minResBalance accountName originatorAccount {balance}}' +
        productMutation +
        'lineDetails { committedAmt uncommittedAmt commitmentAmount tempCommitmentAmount amendmentDate commitmentApprove wetLimit tempWetLimit wetApprove wetDwellDays eNoteDwellDays qcFileReview autoFundAddWires indexUpdateFrequency indexUpdateFreqValue indexAdjuster wetCurtailment startDate endDate firstDaysOfMon lastDaysOfMon contractEffectiveDate contractExpirationDate createdBy createdDate updatedBy updatedDate document {createdBy createdOn documentId documentName documentType documentURL updatedBy updatedOn size } } tenantId } }',
      variables: JSON.parse(
        JSON.stringify({ ...subTenant, subTenantId }),
        this.omitTypename
      ),
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append('lineDetails_contractDocumentUpload', file, file.name);
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addClosingAgentInformation(
    closingAgentObj: ClosingAgentInformation,
    documents: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operations = JSON.stringify({
      query:
        'mutation($name:String!, $status:String, $type:String, $dba:String!, $fka:String, $websiteAddress:String, $yearStarted:String, $altaUniversalId:String, $recertificationDate:String, $lastVerifiedDate:String, $businessLicense:String, $contactName:String!, $phone:String!,$email:String!,$title:String,$verifyFrequency:String,$phoneExt:String,$closingAgentInsurances:[ClosingAgentInsuranceInput]) { closingAgentManage(input: {name: $name,status: $status,type: $type,dba: $dba,fka: $fka,websiteAddress: $websiteAddress,yearStarted: $yearStarted,altaUniversalId: $altaUniversalId,recertificationDate: $recertificationDate,lastVerifiedDate: $lastVerifiedDate,businessLicense: $businessLicense,contactName: $contactName,phone: $phone,email: $email, title: $title,verifyFrequency: $verifyFrequency,phoneExt: $phoneExt,closingAgentInsurances:$closingAgentInsurances}) { closingAgentId name tenantId closingAgentInsurances { insuranceName updatedOn updatedBy document { documentName documentURL documentId documentType updatedBy updatedOn createdBy createdOn size} } } }',
      variables: closingAgentObj,
    });

    if (documents?.eo) {
      formData.append('EO', documents.eo, documents.eo.name);
    }
    if (documents?.fidelity) {
      formData.append('Fidelity', documents.fidelity, documents.fidelity.name);
    }
    if (documents?.surety) {
      formData.append('Surety', documents.surety, documents.surety.name);
    }
    formData.append('operations', operations);
    return this.http.post<any>(
      `${environment.apiUrl}/graphql`,
      formData,
      requestOptions
    );
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  updateClosingAgentInformation(
    closingAgentObj: ClosingAgentInformation,
    documents: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operations = JSON.stringify({
      query:
        'mutation($closingAgentId:ID $name:String!,$status:String,$type:String,$dba:String!,$fka:String,$websiteAddress:String,$yearStarted:String,$altaUniversalId:String,$recertificationDate:String,$lastVerifiedDate:String,$businessLicense:String,$contactName:String!,$phone:String!,$email:String!,$title:String,$verifyFrequency:String,$phoneExt:String,$closingAgentInsurances:[ClosingAgentInsuranceInput]) { closingAgentManage(input: {closingAgentId:$closingAgentId name: $name,status: $status,type: $type,dba: $dba,fka: $fka,websiteAddress: $websiteAddress,yearStarted: $yearStarted,altaUniversalId: $altaUniversalId,recertificationDate: $recertificationDate,lastVerifiedDate: $lastVerifiedDate,businessLicense: $businessLicense,contactName: $contactName,phone: $phone,email: $email, title: $title,verifyFrequency: $verifyFrequency,phoneExt: $phoneExt,closingAgentInsurances:$closingAgentInsurances}) { closingAgentId name tenantId closingAgentInsurances { insuranceName updatedOn updatedBy document { documentName documentURL documentId documentType updatedBy updatedOn createdBy createdOn size} } } }',
      variables: closingAgentObj,
    });
    if (documents?.eo && documents?.eo instanceof File) {
      formData.append('EO', documents.eo, documents.eo.name);
    }
    if (documents?.fidelity && documents?.fidelity instanceof File) {
      formData.append('Fidelity', documents.fidelity, documents.fidelity.name);
    }
    if (documents?.surety && documents?.surety instanceof File) {
      formData.append('Surety', documents.surety, documents.surety.name);
    }
    formData.append('operations', operations);
    return this.http.post<any>(
      `${environment.apiUrl}/graphql`,
      formData,
      requestOptions
    );
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  updateLoan(loanObj: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($borrowers:[BorrowerInput], $documents:[DocumentInput], $loanClosingAgent:LoanClosingAgentInput, $collateral:CollateralInput, $investor:InvestorInput, $loanDetail:LoanDetailInput, $originator:OriginatorInput, $fortaLoanId: ID, $fedRefNumber: String, $submitflag: Boolean, $productName: String!, $subTenantId: String, $status: String) { updateLoan(loan: { borrowers: $borrowers, documents: $documents, loanClosingAgent:$loanClosingAgent, collateral:$collateral, investor:$investor, loanDetail: $loanDetail, originator: $originator, fortaLoanId: $fortaLoanId, fedRefNumber: $fedRefNumber, productName: $productName, subTenantId: $subTenantId, status: $status }, submitflag: $submitflag) { subTenantId tenantId fortaLoanId status createdBy createdOn updatedBy updatedOn displayLoanId} }',
      variables: JSON.parse(JSON.stringify(loanObj), this.omitTypename),
    });
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  updateSubTenantInvestors(
    imbInvestorsObj: any,
    documents: any[]
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($subTenantInvestorInput : SubTenantInvestorInput) {saveSubtenantInvestors (subTenantInvestorInput : $subTenantInvestorInput) { investorId isApproved exposureLimit imbLimit email document {documentId documentURL documentName size} }}',
      variables: JSON.parse(JSON.stringify(imbInvestorsObj), this.omitTypename),
    });
    documents.forEach((document: any) => {
      if (!document.documentURL)
        formData.append(
          document.documentType,
          document.file,
          this.utilsService.removeSpaceBtFilename(document.file.name)
        );
    });
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  private omitTypename(key: any, value: any) {
    return key === '__typename' ? undefined : value;
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  manageClosingAgentStateLicenses(
    stateLicenseInput: any,
    documents: any[]
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($originId : String!, $stateLicenses : [StateLicense]!) { manageClosingAgentStateLicenses (stateLicenseInput: { originId :$originId, stateLicenses : $stateLicenses}){ stateLicenseId stateName licenseExpirationDate licenseNumber document { documentId documentName documentType size documentURL }}}',
      variables: stateLicenseInput,
    });
    documents.forEach((document: any) => {
      if (document.document) {
        if (document.document.file) {
          formData.append(
            document.stateName,
            document.document.file,
            document.document.file.name
          );
        } else {
          formData.append(
            document.stateName,
            new Blob(),
            document.document.documentName
          );
        }
      } else {
        formData.append(document.stateName, new Blob(), '');
      }
    });
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  manageSubTenantStateLicenses(
    stateLicenseInput: any,
    documents: any[]
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($originId : String!, $stateLicenses : [StateLicense]!) { manageSubTenantStateLicenses (stateLicenseInput: { originId :$originId, stateLicenses : $stateLicenses}){ stateLicenseId stateName licenseExpirationDate licenseNumber document { documentId documentName documentType size documentURL }}}',
      variables: stateLicenseInput,
    });
    documents.forEach((document: any) => {
      if (document.document) {
        if (document.document.file) {
          formData.append(
            document.stateName,
            document.document.file,
            document.document.file.name
          );
        } else {
          formData.append(
            document.stateName,
            new Blob(),
            document.document.documentName
          );
        }
      } else {
        formData.append(document.stateName, new Blob(), '');
      }
    });
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  createWireForIMB(imbWireImput: ImbWireInput, document: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($settlementMethod: String!,$loanInfos: [LoanInfoInput]!, $purchaseAdviceAmount: Float, $matchedWireId: String, $originatorName:String!) { addSettlementRequest(settlementRequestInput: { settlementMethod: $settlementMethod,loanInfos: $loanInfos, purchaseAdviceAmount: $purchaseAdviceAmount, matchedWireId:$matchedWireId, originatorName: $originatorName }) {settlementRequestId, wireId, settlementMethod, purchaseAdviceAmount, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId,status,loanInfos { fortaLoanId, investorProceeds},createdBy,sourceForm,createdDate,updatedBy,updatedDate,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: imbWireImput,
    });

    if (document) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.PURCHASE_ADVICE_DOCUMENT,
        document.file,
        document.file.name
      );
    }
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addLoansToWire(
    addLoansToWire: AddLoansToWire,
    document: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($loanInfos: [LoanInfoInput]!, $purchaseAdviceAmount: Float!, $wireId: String!) { addLoansToWire(addLoansToWireInput: { loanInfos: $loanInfos,, purchaseAdviceAmount: $purchaseAdviceAmount, wireId:$wireId }) {settlementRequestId, wireId, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId, status,loanInfos { fortaLoanId, investorProceeds},createdBy,createdDate,updatedBy,updatedDate,sumOfInvestorProceeds,purchaseAdviceAmount,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: addLoansToWire,
    });
    if (document) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.PURCHASE_ADVICE_DOCUMENT,
        document.file,
        document.file.name
      );
    }
    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addWire(settlementWire: SettlementWire, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($wireId:String,$referenceNumber:String!,$receivedDate:String!,$wireAmount:Float!,$comment:String,$investorName:String,$originatorName:String!,$investorId:String,$subTenantId:String,$returnFromCA:Boolean!) { addWire(wireInput: {wireId: $wireId,referenceNumber: $referenceNumber,receivedDate: $receivedDate,wireAmount: $wireAmount,comment: $comment,investorName: $investorName,originatorName: $originatorName,investorId: $investorId,subTenantId: $subTenantId,returnFromCA:$returnFromCA}) {settlementRequestId, wireId, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,sourceForm,originatorName,subTenantId, status,loanInfos{fortaLoanId, investorProceeds},createdBy,createdDate,updatedBy,updatedDate,returnFromCA,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: settlementWire,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.WIRE_CONFIRMATION_DOCUMENT,
        file,
        file.name
      );
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  editWire(settlementWire: SettlementWire, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($wireId:String!,$referenceNumber:String!,$receivedDate:String!,$wireAmount:Float!,$comment:String,$investorName:String,$originatorName:String!,$investorId:String,$subTenantId:String,$returnFromCA: Boolean!,$deleteFlag: Boolean) { updateWire(updateWireInput: {wireId: $wireId,referenceNumber: $referenceNumber,receivedDate: $receivedDate,wireAmount: $wireAmount,comment: $comment,investorName: $investorName,originatorName: $originatorName,investorId: $investorId,subTenantId: $subTenantId,returnFromCA:$returnFromCA,deleteFlag:$deleteFlag}) {wireId, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId, sourceForm,status,loanInfos{fortaLoanId, investorProceeds},createdBy,createdDate,updatedBy,updatedDate,returnFromCA,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: settlementWire,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.WIRE_CONFIRMATION_DOCUMENT,
        file,
        file.name
      );
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  uploadBulkLoanRequestFiles(
    files: File[]
  ): Observable<ApolloQueryResult<Mutation>> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation { uploadBulkLoanRequestFiles { fileName fileKey data { record { key value mappedValue }}}}',
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (files) {
      files.forEach((file: File) => {
        formData.append('bulkLoanFile', file, file.name);
      });
    }
    return this.http.post<ApolloQueryResult<Mutation>>(
      uri,
      formData,
      requestOptions
    );
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  linkWire(linkWire: LinkWire, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($settlementWireId:String!,$matchedWireId:String!,$document:DocumentInput) { linkWire(linkWireInput: {settlementWireId: $settlementWireId,matchedWireId: $matchedWireId,document:$document}) {settlementRequestId, wireId, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId, status,loanInfos{fortaLoanId, investorProceeds},createdBy,createdDate,updatedBy,updatedDate,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: linkWire,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.WIRE_CONFIRMATION_DOCUMENT,
        file,
        file.name
      );
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  updateLoanCondition(
    updateCondition: UpdateLoanCondition,
    file: File
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($fortaLoanId: String!,$conditionId: String!, $decision: String!, $explanation: String!, $conditionTrigger : String!) {updateLoanCondition(fortaLoanId: $fortaLoanId,conditionId: $conditionId, decision: $decision,explanation: $explanation, conditionTrigger : $conditionTrigger){ decisionMadeBy decisionDate decisionExplanation conditionDecision status statusChangeLog conditionId conditionName conditionValue softCondition conditionTrigger overridable externalViewable createdBy createdDate }}',
      variables: updateCondition,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append('condition', file, file.name);
    }

    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  linkToLoan(linkToLoanInput: LinkToLoanInput, file: File): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($referenceNumber:String!,$receivedDate:String!,$wireAmount:Float!,$comment:String,$investorName:String!,$originatorName:String!,$investorId:String,$subTenantId:String,$returnFromCA:Boolean,$matchedWireId:String!) { linkToLoan(linkToLoanInput: {referenceNumber: $referenceNumber,receivedDate: $receivedDate,wireAmount:$wireAmount, comment:$comment,investorName:$investorName,originatorName:$originatorName, investorId:$investorId,subTenantId:$subTenantId,returnFromCA:$returnFromCA,matchedWireId:$matchedWireId,}) {settlementRequestId, wireId, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId, status,loanInfos{fortaLoanId, investorProceeds},createdBy,createdDate,updatedBy,updatedDate,documents { documentId, documentName, documentType, documentURL, size }} }',
      variables: linkToLoanInput,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.WIRE_CONFIRMATION_DOCUMENT,
        file,
        file.name
      );
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  addDocument(
    addDocument: AddDocument,
    documentType: string,
    file: File
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation($folderPath1:String!,$folderPath2:String!,$folderPath3:String!,$originId:String!,$origin:String!,$originType:String!) { addDocument(documentCommonInput: {folderPath1: $folderPath1,folderPath2: $folderPath2,folderPath3:$folderPath3, originId:$originId,origin:$origin,originType:$originType}) {documentId, documentName, documentType, documentURL, size } }',
      variables: addDocument,
    });
    const formData = new FormData();
    formData.append('operations', operations);
    if (file) {
      formData.append(documentType, file, file.name);
    }
    return this.http.post(uri, formData, requestOptions);
  }

  /**
   * @deprecated This is using a post for graphql.. DO NOT USE or ADD to this!
   */
  manageClosingAgentBranch(closingAgentBranchInput: any): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    const operations = JSON.stringify({
      query:
        'mutation ClosingAgentBranchManage($closingAgentBranchInput: ClosingAgentBranchInput) {closingAgentBranchManage(input: $closingAgentBranchInput) { branchId closingAgentId name status address address2 city zip contactName contactTitle email state primaryContactPhone phoneExt vendorValidationCode isVerified recertDate verifiedWith verifiedBy verifiedDate contactPhone accountIds documents { documentURL, size, updatedBy, documentId, documentName } } }',
      variables: { closingAgentBranchInput: closingAgentBranchInput },
    });
    const formData = new FormData();
    formData.append('operations', operations);
    return this.http.post(uri, formData, requestOptions);
  }

  updateWireForIMB(
    updateImbWireImput: UpdateImbWireInput,
    document: any
  ): Observable<any> {
    const headers = new HttpHeaders({
      'x-correlation-id': UUID.UUID(),
    });
    const requestOptions = { headers: headers };
    var formData = new FormData();
    const operation = JSON.stringify({
      query:
        'mutation($settlementRequestId:String!,$settlementRequest:SettlementRequestInput) { updateSettlementRequest(updateSettlementRequestInput:{settlementRequestId:$settlementRequestId,settlementRequest:$settlementRequest}) {settlementRequestId, wireId, settlementMethod, referenceNumber, receivedDate,wireAmount, comment,investorName,investorId,originatorName,subTenantId, status,sumOfInvestorProceeds,purchaseAdviceAmount,createdBy,sourceForm,createdDate,updatedBy,updatedDate, loanInfos{ fortaLoanId, investorProceeds}, documents { documentId, documentName, documentType, documentURL, size } } }',
      variables: updateImbWireImput,
    });

    if (
      updateImbWireImput.settlementRequest.settlementMethod ===
      SETTLEMENT_REFERENCE_TYPES.OPERATIONAL_ACCOUNT
    ) {
      formData.append(
        SETTELEMENT_DOCUMENT_TYPES.PURCHASE_ADVICE_DOCUMENT,
        new Blob(),
        ''
      );
    } else {
      if (document) {
        if (!document.documentURL) {
          formData.append(
            SETTELEMENT_DOCUMENT_TYPES.PURCHASE_ADVICE_DOCUMENT,
            document.file,
            document.file.name
          );
        } else {
          formData.append(
            SETTELEMENT_DOCUMENT_TYPES.PURCHASE_ADVICE_DOCUMENT,
            new Blob(),
            document.documentName
          );
        }
      }
    }

    formData.append('operations', operation);
    return this.http.post(uri, formData, requestOptions);
  }
}
