import { ClosingAgentBranch } from './approved-closing-agent.interface';
import { ClosingAgentAccount } from './closing-agent-account.interface';
import { NewWireType } from 'src/app/graphql/generated';

export enum WireType {
  OutgoingOriginalWire = 'OutgoingOriginalWire',
  OutgoingAdditionalWire = 'OutgoingAdditionalWire',
}

export const WireTypeMapForDisplay = {
  [WireType.OutgoingOriginalWire]: 'Original',
  [WireType.OutgoingAdditionalWire]: 'Additional',
};

export const WireStatusMap = {
  WIRE_DRAFT: 'Draft',
  WIRE_STATUS_UNSPECIFIED: 'Unspecified',
  WIRE_NEW: 'New',
  WIRE_SUBMITTED: 'Submitted',
  WIRE_PENDING_APPROVAL: 'Pending Approval',
  WIRE_APPROVED: 'Approved',
  WIRE_PROCESSING: 'Processing',
  WIRE_FUNDED: 'Funded',
  WIRE_SHIPPED: 'Shipped',
  WIRE_SETTLED: 'Settled',
  WIRE_CANCELLED: 'Cancelled',
  WIRE_RETURNED: 'Returned',
};

export enum WireStatus {
  WIRE_DRAFT = 'WIRE_DRAFT',
  WIRE_STATUS_UNSPECIFIED = 'WIRE_STATUS_UNSPECIFIED',
  WIRE_NEW = 'WIRE_NEW',
  WIRE_SUBMITTED = 'WIRE_SUBMITTED',
  WIRE_PENDING_APPROVAL = 'WIRE_PENDING_APPROVAL',
  WIRE_APPROVED = 'WIRE_APPROVED',
  WIRE_PROCESSING = 'WIRE_PROCESSING',
  WIRE_FUNDED = 'WIRE_FUNDED',
  WIRE_SHIPPED = 'WIRE_SHIPPED',
  WIRE_SETTLED = 'WIRE_SETTLED',
  WIRE_CANCELLED = 'WIRE_CANCELLED',
  WIRE_RETURNED = 'WIRE_RETURNED',
}

export enum WireQueryStatus {
  WIRE_DRAFT = 'Draft',
  WIRE_STATUS_UNSPECIFIED = 'Unspecified',
  WIRE_NEW = 'New',
  WIRE_SUBMITTED = 'Submitted',
  WIRE_PENDING_APPROVAL = 'Pending Approval',
  WIRE_APPROVED = 'Approved',
  WIRE_PROCESSING = 'Processing',
  WIRE_FUNDED = 'Funded',
  WIRE_SHIPPED = 'Shipped',
  WIRE_SETTLED = 'Settled',
  WIRE_CANCELLED = 'Cancelled',
  WIRE_RETURNED = 'Returned',
}

export interface NewWireRecipient {
  closingAgentId: string;
  name: string;
  type: string;
  closingAgentAccounts: ClosingAgentAccount[];
}

export interface IOutGoingWireFormValues {
  type?: NewWireType;
  amountTotal?: number;
  amountWH?: number;
  amountOP?: number;
  status?: WireStatus;
  wireRequestDate?: string | null;
  closingAgentName?: string;
  accountName?: string;
  closingAgentBranch?: string;
  closingAgentAddress?: string;
  closingAgentCity?: string;
  closingAgentState?: string;
  closingAgentZip?: string;
  bankName?: string;
  primaryBankABA?: string;
  secureInsightEACode?: string;
  accountNumber?: string;
  orderNumber?: string;
  oBI?: string;
  fedRefNumber?: string;
  closingAgent?: string;
  __typename?: WireType;
}

export interface IWireFormRawValue {
  id?: string | null;
  type?: string | null;
  amountTotal?: number | null;
  amountWH?: number | null;
  amountOP?: number | null;
  wireRequestDate?: string | null;
  fedRefNumber?: string | null;
  obiText?: string | null;
  bankAddress?: string | null;
  bankCity?: string | null;
  bankState?: string | null;
  bankZip?: string | null;
  accountNumber?: string | null;
  accountNumberFTC?: string | null;
  primaryBankABA?: string | null;
  vendorCode?: string | null;
  closingAgentId?: string | null;
  closingAgentBranchId?: string | null;
}

export interface OutgoingWireInput {
  id?: string;
  type: NewWireType;
  amountTotal?: number;
  amountWH?: number;
  amountOP?: number;
  status?: WireStatus;
  wireRequestDate?: string;
  fedRefNumber?: string;
  obiText?: string;
  wireRecipientId?: string;
  accountNumber?: string;
  primaryBankABA?: string;
  branchId?: string;
  closingAgentBranch?: ClosingAgentBranch;
  requestedWireDate?: string;
  wireRecipient?: NewWireRecipient;
  account?: ClosingAgentAccount;
  branch?: ClosingAgentBranch;
  vendorCode?: string;
  accountNumberFTC?: string;
  fctInformationFG?: ClosingAgentBranch;
  __typename?: string;
}

export interface IOutgoingWireResponse {
  status: WireStatus;
  amountTotal: number;
  amountWH: number;
  amountOP: number;
  wireRequestDate: string;
  fedRefNumber: string;
  obiText: string;
  wireRecipient: NewWireRecipient;
  __typename: string;
}
