import { AgilityExportButtonModule } from './components/agility-export-button/agility-export-button.module';
import { CommonModule, CurrencyPipe, DatePipe } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  HttpClient,
  HttpClientModule,
} from '@angular/common/http';
import { inject, ModuleWithProviders, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';

import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { ConfirmationService, MessageService } from 'primeng/api';
import { BreadcrumbModule } from 'primeng/breadcrumb';
import { ButtonModule } from 'primeng/button';
import { CalendarModule } from 'primeng/calendar';
import { CardModule } from 'primeng/card';
import { CarouselModule } from 'primeng/carousel';
import { CheckboxModule } from 'primeng/checkbox';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { DividerModule } from 'primeng/divider';
import { DropdownModule } from 'primeng/dropdown';
import {
  DialogService,
  DynamicDialogConfig,
  DynamicDialogRef,
} from 'primeng/dynamicdialog';
import { InputMaskModule } from 'primeng/inputmask';
import { InputNumberModule } from 'primeng/inputnumber';
import { InputSwitchModule } from 'primeng/inputswitch';
import { InputTextModule } from 'primeng/inputtext';
import { MenuModule } from 'primeng/menu';
import { MessageModule } from 'primeng/message';
import { MultiSelectModule } from 'primeng/multiselect';
import { PanelModule } from 'primeng/panel';
import { PasswordModule } from 'primeng/password';
import { PickListModule } from 'primeng/picklist';
import { RadioButtonModule } from 'primeng/radiobutton';
import { RippleModule } from 'primeng/ripple';
import { ScrollPanelModule } from 'primeng/scrollpanel';
import { SidebarModule } from 'primeng/sidebar';
import { SkeletonModule } from 'primeng/skeleton';
import { StepsModule } from 'primeng/steps';
import { TableModule } from 'primeng/table';
import { TabMenuModule } from 'primeng/tabmenu';
import { TabViewModule } from 'primeng/tabview';
import { ToastModule } from 'primeng/toast';
import { TooltipModule } from 'primeng/tooltip';
import { TreeTableModule } from 'primeng/treetable';

import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { PageNotFoundComponent } from './components/page-not-found/page-not-found.component';
import { PrivacyPolicyComponent } from './components/privacy-policy/privacy-policy.component';
import { TermsComponent } from './components/terms/terms.component';

import { ApiCallsService } from './services/api-calls/api-calls.service';
import { CustomEventsService } from './services/custom-events/custom-events.service';
import { I18nService } from './services/i18n/i18n.service';
import { LocalStorageService } from './services/local-storage/local-storage.service';
import { ThemeService } from './services/theme/theme.service';

import { CustomEventTokens } from './models/custom-event-tokens';

import { ErrorCatchingInterceptor } from './interceptors/error-catching/error-catching.interceptor';

import {
  AuthGuard,
  AuthHttpInterceptor,
  AuthModule,
} from '@auth0/auth0-angular';
import { AccordionModule } from 'primeng/accordion';
import { BadgeModule } from 'primeng/badge';
import { ChartModule } from 'primeng/chart';
import { ChipsModule } from 'primeng/chips';
import { FileUploadModule } from 'primeng/fileupload';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { PanelMenuModule } from 'primeng/panelmenu';
import { TimelineModule } from 'primeng/timeline';
import { ToggleButtonModule } from 'primeng/togglebutton';
import { environment as env } from '../../environments/environment';
import { CustomModule } from '../custom/custom.module';
import { GraphQLModule } from '../gql-apollo/graphql.module';
import { AllFiltersComponent } from './components/all-filters/all-filters.component';
import { CommentsComponent } from './components/comments/comments.component';
import { CreateReportComponent } from './components/create-report/create-report.component';
import { DashboardCardComponent } from './components/dashboard-card/dashboard-card.component';
import { FurtherCreditToInfoComponent } from './components/further-credit-to-info/further-credit-to-info.component';
import { GlobalLoanCardComponent } from './components/global-loan-card/global-loan-card.component';
import { GlobalLoanSearchComponent } from './components/global-loan-search/global-loan-search.component';
import { LedgerDetailsComponent } from './components/ledger-details/ledger-details.component';
import { LoanFileImportComponent } from './components/loan-file-import/loan-file-import.component';
import { LoanSearchComponent } from './components/loan-search/loan-search.component';
import { MobileNavigationComponent } from './components/mobile-navigation/mobile-navigation.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { NavigationComponent } from './components/navigation/navigation.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { UpcomingEventComponent } from './components/upcoming-event/upcoming-event.component';
import { UserRolesComponent } from './components/user-roles/user-roles.component';
import { CalenderMaskDirective } from './directives/calendar-mask.directive';
import { CheckPermissionsDirective } from './directives/check-permission.directive';
import { AdminGuard } from './guards/admin/admin.guard';
import { PermissionGuard } from './guards/permission/permission.guard';
import { PermissionService } from './guards/permission/service/permission.service';
import { FileSizePipe } from './pipes/fiile-size/file-size.pipe';
import { IndexofPipe } from './pipes/indexof.pipe';
import { PhoneNumberPipe } from './pipes/phoneNumber/phoneNumber.pipe';
import { PluralizePipe } from './pipes/pluralize/pluralize.pipe';
import { ReplacePipe } from './pipes/replace/replace.pipe';
import { TruncatePipe } from './pipes/truncate/truncate.pipe';
import { ClosingAgentService } from './services/closing-agent/closing-agent.service';
import { CsvService } from './services/csv/csv.service';
import { FundingDashboardService } from './services/dashboard/funding-dashboard.service';
import { SidebarService } from './services/dashboard/sidebar.service';
import { EnumerationsService } from './services/enumerations/enumerations.service';
import { JwtService } from './services/jwt.service';
import { LoaderService } from './services/loader/loader.service';
import { LoanConditionsService } from './services/loan-conditions.service';
import { LoanSearchService } from './services/loan-search/loan-search.service';
import { ToastMessageService } from './services/toast-message.service';
import { UserService } from './services/user.service';
import { UtilsService } from './services/utils/utils.service';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { NgxInfiniteScrollComponent } from './ngx-infinite-scroll/ngx-infinite-scroll.component';
import { ErrorComponent } from './components/error/error.component';
import { UrlParamsService } from './services/url-params.service';
import { PipesModule } from './pipes/pipes.module';
@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    TermsComponent,
    ErrorComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    PageNotFoundComponent,
    NavigationComponent,
    MobileNavigationComponent,
    UpcomingEventComponent,
    FileSizePipe,
    TruncatePipe,
    ReplacePipe,
    PluralizePipe,
    PhoneNumberPipe,
    CommentsComponent,
    AllFiltersComponent,
    DashboardCardComponent,
    SidebarComponent,
    LoanFileImportComponent,
    CheckPermissionsDirective,
    LoanSearchComponent,
    GlobalLoanSearchComponent,
    GlobalLoanCardComponent,
    IndexofPipe,
    UserRolesComponent,
    LedgerDetailsComponent,
    NavbarComponent,
    CreateReportComponent,
    CalenderMaskDirective,
    FurtherCreditToInfoComponent,
    LedgerDetailsComponent,
    NgxInfiniteScrollComponent,
  ],
  imports: [
    PipesModule,
    AgilityExportButtonModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule.withConfig({
      callSetDisabledState: 'whenDisabledForLegacyCode',
    }),
    RouterModule,
    HttpClientModule,
    CardModule,
    PasswordModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    SidebarModule,
    ToastModule,
    ConfirmDialogModule,
    TabMenuModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    MessageModule,
    BreadcrumbModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    InputMaskModule,
    InputSwitchModule,
    MenuModule,
    StepsModule,
    TableModule,
    TreeTableModule,
    TooltipModule,
    PickListModule,
    FileUploadModule,
    GraphQLModule,
    AccordionModule,
    InputTextareaModule,
    ChipsModule,
    TimelineModule,
    ToggleButtonModule,
    PanelModule,
    DividerModule,
    ScrollPanelModule,
    PanelMenuModule,
    ChartModule,
    SkeletonModule,
    BadgeModule,
    CarouselModule,
    OverlayPanelModule,
    CustomModule,
    InfiniteScrollModule,
    TranslateModule.forRoot({
      defaultLanguage: CustomEventTokens.ENGLISH_LANGUAGE,
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    AuthModule.forRoot({
      ...env.auth,
      skipRedirectCallback: new UrlParamsService(document).hasErrorParams(),
      httpInterceptor: {
        ...env.httpInterceptor,
      },
    }),
  ],
  exports: [
    PipesModule,
    PanelMenuModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    HttpClientModule,
    CardModule,
    PasswordModule,
    RippleModule,
    ButtonModule,
    InputTextModule,
    SidebarModule,
    ToastModule,
    ConfirmDialogModule,
    HeaderComponent,
    FooterComponent,
    TermsComponent,
    PrivacyPolicyComponent,
    ContactUsComponent,
    PageNotFoundComponent,
    TabMenuModule,
    TabViewModule,
    DropdownModule,
    MultiSelectModule,
    CalendarModule,
    MessageModule,
    BreadcrumbModule,
    InputNumberModule,
    CheckboxModule,
    RadioButtonModule,
    ToggleButtonModule,
    InputMaskModule,
    InputSwitchModule,
    MenuModule,
    StepsModule,
    TableModule,
    TreeTableModule,
    PickListModule,
    FileUploadModule,
    GraphQLModule,
    TranslateModule,
    UpcomingEventComponent,
    AccordionModule,
    FileSizePipe,
    ReplacePipe,
    TruncatePipe,
    PluralizePipe,
    IndexofPipe,
    PhoneNumberPipe,
    InputTextareaModule,
    ChipsModule,
    TimelineModule,
    PanelModule,
    DividerModule,
    SkeletonModule,
    ScrollPanelModule,
    CommentsComponent,
    AllFiltersComponent,
    DashboardCardComponent,
    SidebarComponent,
    LoanFileImportComponent,
    CheckPermissionsDirective,
    BadgeModule,
    CarouselModule,
    OverlayPanelModule,
    UserRolesComponent,
    CustomModule,
    LedgerDetailsComponent,
    CreateReportComponent,
    CalenderMaskDirective,
    FurtherCreditToInfoComponent,
    InfiniteScrollModule,
  ],
})
export class SharedModule {
  static forRoot(): ModuleWithProviders<SharedModule> {
    return {
      ngModule: SharedModule,
      providers: [
        MessageService,
        ConfirmationService,
        CustomEventsService,
        LocalStorageService,
        ApiCallsService,
        EnumerationsService,
        AuthGuard,
        AdminGuard,
        ThemeService,
        I18nService,
        UtilsService,
        LoanSearchService,
        DialogService,
        DynamicDialogRef,
        DynamicDialogConfig,
        LoanConditionsService,
        LoanSearchService,
        ClosingAgentService,
        DatePipe,
        CurrencyPipe,
        FileSizePipe,
        TruncatePipe,
        ReplacePipe,
        CsvService,
        ToastMessageService,
        UserService,
        LoaderService,
        JwtService,
        PermissionService,
        PermissionGuard,
        SidebarService,
        FundingDashboardService,
        {
          provide: HTTP_INTERCEPTORS,
          useClass: AuthHttpInterceptor,
          multi: true,
        },
        {
          provide: HTTP_INTERCEPTORS,
          useClass: ErrorCatchingInterceptor,
          multi: true,
        },
      ],
    };
  }
}
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}
