import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { BehaviorSubject } from 'rxjs';
import { CustomEventTokens } from 'src/app/shared/models/custom-event-tokens';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Injectable({
  providedIn: 'root',
})
export class PermissionService {
  public banner: string =
    'Some actions may be restricted on this page due to your permissions. Contact your system admin for access.';
  public tooltip: string = 'This action is restricted due to permissions';
  public activeWireIdExists: string = 'Only one wire can be active at a time';
  public isAuthenticated: boolean = false;

  constructor(
    private authService: AuthService,
    private localStorageService: LocalStorageService
  ) {
    this.authService.isAuthenticated$.subscribe((authenticated) => {
      this.isAuthenticated = authenticated;
    });
  }

  private _showPermissionsBanner$: BehaviorSubject<boolean> =
    new BehaviorSubject<boolean>(false);

  getShowPermissionsBanner(): BehaviorSubject<boolean> {
    return this._showPermissionsBanner$;
  }

  setShowPermissionsBanner(showPermissionsBanner: boolean) {
    this._showPermissionsBanner$.next(showPermissionsBanner);
  }

  hasPermission({ permission }: any): boolean {
    const permissionFound = this.localStorageService
      .getLocalStorageItem(CustomEventTokens.AGILITY_USER)
      ?.permissions?.some(
        (userPermission: string) =>
          userPermission.toUpperCase() === permission.toUpperCase()
      );
    return permissionFound && this.isAuthenticated;
  }

  hasPermissions(permissions: Array<any>, operation: string) {
    if (permissions.length) {
      if (operation === 'OR') {
        let flag = permissions
          .map((permission: string) => {
            return this.hasPermission({
              permission: permission,
            });
          })
          .reduce(
            (accumulator: boolean, currentValue: boolean) =>
              accumulator || currentValue,
            false
          );
        return flag;
      } else if (operation === 'AND') {
        let flag = permissions
          .map((permission: string) => {
            return this.hasPermission({
              permission: permission,
            });
          })
          .reduce(
            (accumulator: boolean, currentValue: boolean) =>
              accumulator && currentValue,
            true
          );
        return flag;
      } else return false;
    }
    return false;
  }
}
