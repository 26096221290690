import { CurrencyPipe, DatePipe, PercentPipe } from '@angular/common';
import { Injectable } from '@angular/core';
import {
  AbstractControl,
  FormArray,
  FormControl,
  FormGroup,
  ValidationErrors,
  ValidatorFn,
} from '@angular/forms';
import { Table } from 'primeng/table';
import { PermissionService } from '../../guards/permission/service/permission.service';
import { LocalStorageService } from '../local-storage/local-storage.service';
import { CustomEventTokens } from '../../models/custom-event-tokens';
import { LabelValue } from '../../models/label-value.interface';

@Injectable({
  providedIn: 'root',
})
export class UtilsService {
  constructor(
    private currencyPipe: CurrencyPipe,
    private permissionService: PermissionService,
    private localStorageService: LocalStorageService
  ) {}

  passwordPattern: RegExp =
    /(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[@$!%*#?&^_-]).{8,}/;
  phonePattern: RegExp = /^.*(\d.*){10}.*$/;
  datePattern: RegExp =
    /^(0[1-9]|1[012])[- /.](0[1-9]|[12][0-9]|3[01])[- /.](19|20)\d\d$/;
  namePattern: RegExp = /^[A-Za-z0-9 _.]{3,30}$/;
  amountPattern: RegExp = /^-?(0|[1-9]\d*)?$/;
  alphaNumericWithHyphenPattern: RegExp = /^[ A-Za-z0-9_@./#&+-]*$/;
  zipPattern: RegExp = /^(?!00000)\d{5}$/;
  emailPattern: RegExp = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{3}$/;
  primaryBankABAPattern: RegExp = /^[0-9-]{9}$/;
  accountNumberPattern: RegExp = /^[0-9]{5,17}$/;
  abaFCTPattern: RegExp = /^[0-9]{9}$/;
  readonly ssnPattern: RegExp = /^(?!000-00-0000)\d{3}-\d{2}-\d{4}$/;
  readonly EMPTY_SSN = '___-__-____';
  readonly einPattern: RegExp = /^(?!00-0000000)\d{2}-\d{7}$/;
  readonly EMPTY_EIN = '__-_______';
  noAllZerosPattern: RegExp = /^(?!0+$).*/;
  oBIPattern: RegExp = /^[^\/\\]*$/;
  states: LabelValue[] = this.localStorageService
    .getLocalStorageItem(CustomEventTokens.AGILITY_USER)
    .enumerations?.states?.map(
      (state: { stateName: string; stateCode: string }) => ({
        label: state.stateName,
        value: state.stateCode,
      })
    );

  getSectionAsFormGroup(form: FormGroup, section: string): FormGroup {
    return form.get(section) as FormGroup;
  }

  getFormControl(form: FormGroup, section: string): FormControl {
    return form.get(section) as FormControl;
  }

  getFormatEnteredDate(formGroup: any, controlName: string) {
    let fControl = this.getFormControl(formGroup, controlName);
    if (fControl?.status === 'VALID') {
      fControl.setValue(this.getShortFormDate(fControl?.value));
    }
  }

  getSectionAsFormArray(form: FormGroup, section: string): FormArray {
    return form.get(section) as FormArray;
  }

  removeNonDigits(value?: string | null): string {
    return value?.replace(/\D/g, '') ?? '';
  }

  getFormattedDate(date: Date): string {
    return new DatePipe('en-US').transform(
      date,
      'MM/dd/yyyy hh:mm:ss a'
    ) as string;
  }

  convertNumberToAsterisk(
    value: number | string,
    lastCharacters: number = 5
  ): string {
    let _value = String(value);
    if (_value.length > 0 && _value.length > lastCharacters) {
      return (
        _value.slice(0, _value.length - lastCharacters).replace(/[0-9]/g, '*') +
        _value.slice(-lastCharacters)
      );
    }
    return _value;
  }

  getFormattedCommentedDate(date: Date): string {
    return new DatePipe('en-US').transform(
      date,
      'MM/dd/yyyy hh:mm a'
    ) as string;
  }

  getFormattedDateAlone(date: Date): string {
    if (!date) {
      return '';
    }
    try {
      if (isNaN(date.getTime())) return '';
      return date.toLocaleDateString('en-US', {
        month: '2-digit',
        day: '2-digit',
        year: 'numeric',
      });
    } catch (e) {
      console.warn('Date formatting failed:', e);
      return '';
    }
  }

  getShortFormDate(date: string) {
    if (!date) {
      return '';
    }
    return new Date(date).toLocaleDateString('en-US', {
      month: '2-digit',
      day: '2-digit',
      year: 'numeric',
    });
  }

  getMMDDYYYDate(date: string) {
    return new DatePipe('en-US').transform(date, 'MM-dd-yyyy') as string;
  }

  getCurrency(amount: number): string {
    return this.currencyPipe.transform(amount) as string;
  }

  getPercentage(value: number | string): string {
    if (typeof value === 'string' && value.includes('%')) {
      return value as string;
    }
    return new PercentPipe('en-US').transform(
      Number(value) / 100,
      '1.2-2'
    ) as string;
  }

  getNumberOrNull(value?: string | number | null): number | null {
    if (typeof value === 'number') {
      return value;
    } else if (typeof value === 'string') {
      const parsedValue = parseFloat(value);
      if (!isNaN(parsedValue)) {
        return parsedValue;
      }
    }
    return null;
  }

  removeTypeNameProperty(target: Object) {
    return JSON.parse(JSON.stringify(target), this.omitTypename);
  }

  private omitTypename(key: any, value: any) {
    return key === '__typename' ? undefined : value;
  }
  removeSpaceBtFilename(fileName: string) {
    return fileName.replace(/\s/g, '');
  }
  getFormattedDateAloneFromString(dateString: string) {
    if (dateString == '') {
      return '';
    }
    const date = new Date(dateString);
    return this.getFormattedDateAlone(date);
  }
  getFormattedFullDate(dateString: string): string {
    // Month Date Year, HH:MM:SS] (E.g. January 25th 2024, 11:28:20 AM)

    if (dateString.length === 0) {
      return dateString;
    }

    const date = new Date(dateString);

    const options: Intl.DateTimeFormatOptions = {
      year: 'numeric',
      month: 'long', // takes care of Month name
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
      second: '2-digit',
      hour12: true, // takes care of AM/PM
    };

    let localeString: string = date.toLocaleString('en-US', options);

    // Add a suffix st/nd/rd/th after the date
    const day: number = date.getUTCDate();
    const dayWithOrdinal: string =
      day +
      (day % 10 === 1 && day !== 11
        ? 'st'
        : day % 10 === 2 && day !== 12
        ? 'nd'
        : day % 10 === 3 && day !== 13
        ? 'rd'
        : 'th');

    // Add a comma after the year
    localeString = localeString.replace(day.toString(), dayWithOrdinal);
    localeString = localeString.replace(/, (\d{4}) at/, ' $1,');

    return localeString;
  }

  intToRGB(i: number) {
    var c = (i & 0x00ffffff).toString(16).toUpperCase();
    return '#' + '00000'.substring(0, 6 - c.length) + c;
  }

  getRandomInt(min: number, max: number) {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min) + min);
  }

  getBorrowerName(borrowers: Array<any>, onlyLastName: boolean): string {
    const borrower = borrowers?.find((borrower: any) => borrower.primary);
    switch (borrower?.borrowerType) {
      case 'Individual':
        return onlyLastName
          ? borrower.lastName
          : `${borrower.lastName}, ${borrower.firstName}`;
      case 'Business':
        return borrower.businessName;
      case 'Trust':
        return borrower.trustName;
    }
    return '';
  }

  applyFilterGlobal(myTab: Table | undefined, event: any, stringValue: any) {
    myTab!.filterGlobal((event.target as HTMLInputElement).value, stringValue);
  }

  getFullAddress(collateral: any): string {
    return `${collateral?.address}, ${collateral?.city}, ${collateral?.state}, ${collateral?.zip}`;
  }

  findIndex(target: Array<any>, key: string, value: string | boolean) {
    let index = -1;
    index = target.findIndex((object: any) => object[key] === value);
    return index;
  }

  ascendingOrderSort(list: Array<any>, key: string) {
    return list.sort((a: any, b: any) => a[key] - b[key]);
  }

  descendingOrderSort(list: Array<any>, key: string) {
    return list.sort((a: any, b: any) => b[key] - a[key]);
  }

  getStatusClass(status?: string): string {
    switch (status) {
      case 'Returned':
        return 'color-danger';
      case 'New':
        return 'color-success';
      case 'Processing':
        return 'color-slate-200';
      case 'Pending Approval':
        return 'color-warning';
      case 'Approved':
        return 'color-success-dark';
      case 'Funded':
        return 'color-slate-400';
      case 'Shipped':
        return 'color-slate-600';
      default:
        return '';
    }
  }

  formatPhone(value?: string): string | undefined {
    if (!value) {
      return value;
    }
    const digits = this.removeNonDigits(value);
    if (digits?.length !== 10) {
      return value;
    }
    return digits.replace(/^(\d{3})(\d{3})(\d{4})$/, '($1) $2-$3');
  }

  formatToSSN(value: string): string {
    if (value?.length === 9) {
      return (
        value.substring(0, 3) +
        '-' +
        value.substring(3, 5) +
        '-' +
        value.substring(5, 9)
      );
    } else {
      return value;
    }
  }

  formatToEIN(value: string): string {
    if (value?.length === 9) {
      return value.substring(0, 2) + '-' + value.substring(2, 9);
    } else {
      return value;
    }
  }
  getSensitiveEinNumber(borrower: any): string | null {
    return borrower?.einNumber
      ? this.formatToEIN(
          this.permissionService.hasPermission({
            permission: 'VIEW_SENSITIVE_LOAN_DATA',
          })
            ? borrower?.einNumber
            : borrower.einNumber.slice(0, 2) +
                '00000' +
                borrower.einNumber.slice(-2)
        )
      : null;
  }
  getSensitiveSsnNumber(ssnHolder?: { ssn?: string }): string | null {
    return ssnHolder?.ssn
      ? this.formatToSSN(
          this.permissionService.hasPermission({
            permission: 'VIEW_SENSITIVE_LOAN_DATA',
          })
            ? ssnHolder?.ssn
            : '00000' + ssnHolder.ssn
        )
      : null;
  }

  routingNumberValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const targetValue = control?.value;
      if (!targetValue) {
        return null;
      }
      if (targetValue?.length !== 9) {
        return { routingnumber: 'ABA number must be of 9 digits' };
      }
      return this.checksum(targetValue)
        ? null
        : { routingnumber: 'ABA number is not valid' };
    };
  }

  searchUserState(inputState: string): LabelValue | undefined {
    return this.states?.find(
      (state: { label: string; value: string }) => state.value === inputState
    );
  }

  checksum(targetValue: string): boolean {
    if (targetValue?.length !== 9) {
      return false;
    }
    const digits = targetValue?.split('').map((d: string) => parseInt(d, 10));
    if (digits.every((num) => num === 0)) {
      return false;
    }
    const checksum =
      3 * (digits[0] + digits[3] + digits[6]) +
      7 * (digits[1] + digits[4] + digits[7]) +
      (digits[2] + digits[5] + digits[8]);
    return checksum % 10 === 0;
  }
  numberValidator() {
    return (control: any) => {
      const value = control.value;
      if (!value) {
        return null;
      }
      const validPattern = /^\d+$/;
      const valid = validPattern?.test(value);
      if (!valid) {
        return { invalidInput: true };
      }
      return null;
    };
  }

  lengthMatchValidator(): (control: any) => any {
    return (control: any) => {
      if (!control?.value) {
        return null;
      }
      if (control?.value?.length < 5 || control?.value?.length > 17) {
        return { invalidInput: true };
      } else {
        return null;
      }
    };
  }

  disabledTooltip(control: any): boolean {
    return !control?.invalid as boolean;
  }

  addValidators(formControl: any, validators: any) {
    formControl?.setValidators(validators);
    formControl?.updateValueAndValidity({ emitEvent: false });
  }

  removeValidators(formControl: any) {
    formControl?.clearValidators();
    formControl?.updateValueAndValidity({ emitEvent: true });
  }

  optionalSsnValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      if (!value || value === this.EMPTY_SSN) {
        return null;
      }
      const isValid = this.ssnPattern.test(value);
      return isValid ? null : { invalidSSN: true };
    };
  }

  optionalEinValidator(): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
      const value: string = control.value;
      if (!value || value === this.EMPTY_EIN) {
        return null;
      }
      const isValid = this.einPattern.test(value);
      return isValid ? null : { invalidEin: true };
    };
  }
}
